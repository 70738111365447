/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../../Components/BakeryComponents/Banner";
import Navbar from "../../../Components/BakeryComponents/Navbar";
import ProductListing from "../../../Components/BakeryComponents/ProductListing";
import PromotionalModal from "../../../Components/BakeryComponents/PromotionalModal";
import Subscribe from "../../../Components/BakeryComponents/SubscribeModal";
import { Footer } from "../../../Components/Footer/Footer";
import Layout from "../../../Components/Layout/Layout";
import { openNotificationWithIcon } from "../../../Components/Notification/Success.tsx";
import ReserveTable from "../../../Components/ReserveTable/ReserveTable";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../../Helpers/FrontendHelper";

const BakeryTemplate = ({ homePageData, storeState, isLoadingHome }) => {
  const dispatch = useDispatch();
  const [reserverTableModalOpen, setReserverTableModalOpen] = useState(false);

  const [subscribeModalOpen, setSubscribeModalOpen] = useState(false);
  const [promotionModalOpen, setPromotionModalOpen] = useState(false);

  const { unUpdatedHomePageData, storeData } = useSelector(
    (state) => state.storeReducer
  );
  const productVersion = useSelector(
    (state) => state.storeReducer.storeData.productVersion
  );
  const [value, setValue] = React.useState(0);
  const tabRef = useRef();

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);

  const [updatedCartData, setUpdatedCartData] = useState(
    getLocalStorage(`${storeId}-${storeState}`)
  );
  const [activeItemSelected, setActiveItemSelected] = useState(0);

  const [productsWithCategories, setProductsWithCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isPopupClosed, setIsPopClosed] = useState(false);

  useEffect(() => {
    setUpdatedCartData(getLocalStorage(`${storeId}-${storeState}`));
  }, [storeId, storeState]);
  useEffect(() => {
    if (!getLocalStorage(`${storeId}-${storeState}`)?.orderDetails) {
      setLocalStorage(`${storeId}-${storeState}`, {
        storeId: storeId,
        userInformation: {},
        tableId: "",
        orderDetails: [],
        productVersion: productVersion,
      });
    } else {
      if (
        getLocalStorage(`${storeId}-${storeState}`)?.productVersion !=
        productVersion
      ) {
        localStorage.clear();

        function deleteAllCookies() {
          var cookies = document.cookie.split(";");

          for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          }
        }
        deleteAllCookies();
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }
    }
  }, []);
  useEffect(() => {
    setProductsWithCategories(unUpdatedHomePageData?.productsWithCategories);
    setCategories(
      unUpdatedHomePageData?.productsWithCategories?.map((item) => {
        return {
          item: item.categoryName,
          id: item.categoryId,
          count: item.categoryProductCount,
        };
      })
    );
    if (unUpdatedHomePageData?.popUps) {
      setSubscribeModalOpen(true);
    }
    if (unUpdatedHomePageData?.promotionalOffer) {
      setPromotionModalOpen(true);
    }
    if (!activeItemSelected) {
      setActiveItemSelected(
        unUpdatedHomePageData?.productsWithCategories?.[0]?.categoryId
      );
    }
  }, [unUpdatedHomePageData]);
  const addToCartHandler = (item) => {
    const addToCartData = getLocalStorage(`${storeId}-${storeState}`);

    const defaultVariation = item.productVariations?.filter(
      (item) => item.isDefault
    );

    setLocalStorage(`${storeId}-${storeState}`, {
      ...addToCartData,
      orderDetails: [
        ...addToCartData.orderDetails,
        {
          ...item,
          productVariations: item.productVariations
            .map((item) => {
              return { ...item, quantity: 1, isChecked: true };
            })
            ?.filter((item) => item.isDefault),
        },
      ],
    });

    dispatch({
      type: "CHANGE_LOCAL_STORAGE_DATA",
      payload: {
        newData: {
          ...addToCartData,
          orderDetails: [
            ...addToCartData.orderDetails,
            {
              ...item,
              productVariations: item.productVariations
                .map((item) => {
                  return { ...item, quantity: 1, isChecked: true };
                })
                ?.filter((item) => item.isDefault),
            },
          ],
        },
      },
    });

    dispatch(
      openNotificationWithIcon("success", "Product Added to Cart Successfully ")
    );
  };

  const checkOutOfStock = (item) => {
    const defaultVariation = item.productVariations?.filter(
      (item) => item.isDefault
    );

    if (defaultVariation[0]?.stockCount == "0") {
      return {
        isOutOfStock: true,
        outOfStockMessage: defaultVariation[0]?.outOfStockMessage,
      };
    } else {
      return {
        isOutOfStock: false,
      };
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTab = tabRef.current.querySelector(
      `[data-index="${newValue}"]`
    );
    selectedTab.scrollIntoView({ behavior: "smooth", block: "nearest" });
  };

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    if (isMobile) {
      if (value > activeItemSelected) {
        const nextTab = tabRef.current.querySelector(
          `[data-index="${value - 1}"]`
        );
        if (nextTab) {
          setValue(value - 1);
          nextTab.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      } else {
        const nextTab = tabRef.current?.querySelector(
          `[data-index="${value + 1}"]`
        );
        if (nextTab) {
          setValue(value + 1);
          nextTab.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  }, [activeItemSelected]);

  return (
    <>
      <Layout>
        {" "}
        {storeData?.showReserveTableSection && (
          <div className="d-flex justify-content-center align-items-center py-2 bg-white">
            <p className="reservation-message">
              {" "}
              {homePageData?.reservationMessage}
              <a
                onClick={() => {
                  setReserverTableModalOpen(true);
                }}
                className="text-danger fw-bold text-decoration-underline ms-2"
              >
                Reserve Table
              </a>
            </p>
          </div>
        )}
        {homePageData?.alertDescription && (
          <div className=" d-flex justify-content-center align-items-center py-2 bg-white">
            <p className="reservation-message">
              {" "}
              {homePageData?.alertDescription}
            </p>
          </div>
        )}
        <Navbar
          updatedCartData={updatedCartData}
          homePageData={homePageData}
          storeState={storeState}
          setUpdatedCartData={setUpdatedCartData}
        />
        <Banner />
        <ProductListing
          productsWithCategorie={productsWithCategories}
          checkOutOfStock={checkOutOfStock}
          addToCartHandler={addToCartHandler}
          activeItemSelected={activeItemSelected}
          setActiveItemSelected={setActiveItemSelected}
          setIsSearchModalOpen={setIsSearchModalOpen}
          setReserverTableModalOpen={setReserverTableModalOpen}
          alertMessage={true}
        />
        {productsWithCategories?.length > 0 && <Footer />}
      </Layout>

      <Modal
        size="xl"
        show={reserverTableModalOpen}
        className="reserve-table-modal"
        onHide={() => setReserverTableModalOpen(false)}
      >
        <ReserveTable setReserverTableModalOpen={setReserverTableModalOpen} />
      </Modal>
      <Modal
        show={subscribeModalOpen && !isPopupClosed && true}
        onHide={() => {
          setSubscribeModalOpen(false);
          setIsPopClosed(true);
        }}
      >
        <Modal.Body>
          <Subscribe
            setSubscribeModalOpen={setSubscribeModalOpen}
            homePageData={homePageData}
            isLoadingHome={isLoadingHome}
          />
        </Modal.Body>
      </Modal>
      {/* <Modal
        show={promotionModalOpen}
        onHide={() => {
          setPromotionModalOpen(false);
        }}
      >
        <Modal.Body>
      
        </Modal.Body>
      </Modal> */}
      {promotionModalOpen && (
        <PromotionalModal
          setPromotionModalOpen={setPromotionModalOpen}
          homePageData={homePageData}
        />
      )}
    </>
  );
};

export default BakeryTemplate;
